<template>
<div>
  <b-overlay :show="loadingPage" rounded="lg" opacity="0.7">
    <validation-observer ref="simpleRules">

      <!-- BEGIN CARD FEEDBACKS -->
      <div class="p-2 card" v-if="questionario.usarFeedback">
        <b-row>
          <h1 class="question">Intervalo de feedbacks*</h1>
        </b-row>
        <b-row v-if="mostrarAlerta && questionario.usarFeedback">
          <div class="custom-space-alert mt-1 mb-1">
            <b-alert
              :show="mostrarAlerta"
              variant="warning"
            >
              <div class="alert-body">
                <feather-icon
                  class="mr-25"
                  icon="AlertTriangleIcon"
                />
                <span class="ml-25">
                  <b>Pontuação de Feedback.</b>
                  Este formulário possui feedbacks com pontuação, por favor verifique se os intervalos estão preenchidos.
                </span>
              </div>
            </b-alert>
          </div>
        </b-row>
        <b-row >
          <b-col lg="4">
            <b-row>
              <b-col lg="3" class="mt-1 col-img-cora">
                <b-img :src="require('@/assets/images/cora/Cora.png')" class="img-cora-properties"/>
              </b-col>
              <b-col class="mt-4 col-msg-cora">
                <b-row class="row-msg-cora">
                  <span class="elemento"> Oi! <strong> Eu sou a Cora! </strong> </span>
                </b-row>
                <b-row>
                  <span class="elemento mt-1 mb-2">
                    <div class="custom-margin-top-cora" />
                    <div class="description-feedback-area display-colum-for-mobile">
                      <span> Estou aqui para <strong> te ajudar a calcular a pontuação do feedback</strong> do seu questionário! </span>
                      <br />
                      <br />
                      <span>
                        Eu vi que você cadastrou
                        {{this.questionario.questoes.length > 1 ? this.questionario.questoes.length + ' questões' : this.questionario.questoes.length + ' questão'}}
                        e, então, a pontuação mínima possível nesse questionário é de {{this.pontuacao.minimo}}, e o máximo de pontos é {{this.pontuacao.maximo}}.
                      </span>
                      <br />
                      <br />
                      <span>
                        Se você precisar de ajuda, aqui vai uma sugestão de quais intervalos de pontuação você pode usar para cadastrar seus feedbacks:
                      </span>
                    </div>
                    <div class="d-flex justify-content-center">

                      <b-row class="mt-1 mb-1">
                        <b-col align="center">
                          <b-row>
                            <span v-if="this.pontuacao.intervaloA > 0">
                              <feather-icon
                                icon="CheckCircleIcon"
                                size="14"
                                color="#2772C0"
                              />
                              {{this.pontuacao.intervaloA}} a {{this.pontuacao.intervaloB}}
                            </span>
                          </b-row>
                          <b-row>
                            <span v-if="this.pontuacao.intervaloC > 0">
                              <feather-icon
                                icon="CheckCircleIcon"
                                size="14"
                                color="#2772C0"
                              />
                              {{this.pontuacao.intervaloC}} a {{this.pontuacao.intervaloD}}
                            </span>
                          </b-row>
                          <b-row>
                            <span v-if="this.pontuacao.intervaloE > 0">
                              <feather-icon
                                icon="CheckCircleIcon"
                                size="14"
                                color="#2772C0"
                              />
                              {{this.pontuacao.intervaloE}} a {{this.pontuacao.intervaloF}}
                            </span>
                          </b-row>
                          <b-row>
                            <span v-if="this.pontuacao.intervaloG > 0">
                              <feather-icon
                                icon="CheckCircleIcon"
                                size="14"
                                color="#2772C0"
                              />
                              {{this.pontuacao.intervaloG}} a {{this.pontuacao.intervaloH}}
                            </span>
                          </b-row>
                        </b-col>
                      </b-row>

                    </div>
                  </span>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
          <b-col>
            <div v-for="(feedback,i) in questionario.feedbacks" :key="i">
              <div :id="'intervalo'+i" class="mb-2">
                <b-row class="display-cutom-mobile">
                  <b-col>
                    <b-form-input
                      v-model="feedback.valorInicial"
                      id="input-intervalo-inicial"
                      type="number"
                      min="1"
                      max="10"
                      autocomplete="off"
                      :disabled="true"
                    />
                  </b-col>
                  <span> A </span>
                  <b-col>
                    <b-form-input
                      v-if="i === questionario.feedbacks.length-1"
                      v-model="pontuacao.maximo"
                      id="input-intervalo-final"
                      type="number"
                      autocomplete="off"
                      @keyup="alteraValorFeedback(i)"
                      :disabled="true"
                    />
                    <b-form-input
                      v-else
                      v-model="feedback.valorFinal"
                      id="input-intervalo-final"
                      type="number"
                      autocomplete="off"
                      @keyup="alteraValorFeedback(i)"
                      :disabled="false"
                    />
                  </b-col>
                </b-row>
                <b-row class="mt-1">
                  <b-col>
                    <app-collapse>
                      <b-col class="border-collapse">
                        <b-row
                          :class="collapseVisible.visibleOptionsQuestion ? null : 'collapsed'"
                          :aria-expanded="collapseVisible.visibleOptionsQuestion ? 'true' : 'false'"
                          :aria-controls="'collapse-interval'+i"

                          class="mt-1"
                        >
                          <b-col>
                            <h5 class="title-question"> Feedback </h5>
                          </b-col>
                          <b-col align="right">
                            <span>
                              <div
                                v-if="i == questionario.feedbacks.length-1 && questionario.feedbacks.length >2"
                                @click.prevent="removeFeedback()"
                                class="cursor-pointer"
                              >
                                <feather-icon
                                  icon="Trash2Icon"
                                  size="20"
                                />
                              </div>
                            </span>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col lg="12">
                            <b-collapse
                              :id="'collapse-interval'+i"
                              v-model="collapseVisible.visibleOptionsQuestion"
                            >
                              <b-card>
                                <b-form-textarea
                                  v-model="feedback.mensagem"
                                  id="input-descricao"
                                  rows="3"
                                  placeholder="Resposta, escreva aqui a resposta da pergunta. Escreva com uma linguagem clara e objetiva, para não deixar outras duvidas."
                                  autocomplete="off"
                                  maxlength="300"
                                  class="border-0 shadow-none resize-none"
                                  @keyup="verificaFeedbackPreenchido()"
                                />
                              </b-card>
                            </b-collapse>
                          </b-col>
                        </b-row>
                      </b-col>
                    </app-collapse>
                  </b-col>
                </b-row>
              </div>
            </div>
             <b-row>
                  <b-col>
                    <b-button
                      type="submit"
                      variant="custom"
                      class="width-custom"
                      @click.prevent="inserirFeedback()"
                      :disabled="questionario.feedbacks.length >=4 ? true : false"
                    >
                      <span>
                        +
                      </span>
                    </b-button>
                    <span> Inserir mais </span>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="mt-2">
            <div class="separator"></div>
          </b-col>
        </b-row>
      </div>
      <!-- END CARD FEEDBACKS -->

      <!-- BOTÕES -->
      <div class="mb-2 card area-botoes">
        <b-row class="quebra-botoes-mobile mt-2">
         <b-col class="d-flex justify-content-start margin-custom-mobile">
            <b-button
              type="submit"
              variant="outline-primary"
              class="ml-1"
              @click.prevent="exportarQuestionario()"
            >
              <span>
                <feather-icon
                  icon="DownloadIcon"
                  size="14"
                />
               <span v-if="mostrarTextoBotao" class="space-beetwen-button-text"> Exportar </span>
              </span>
            </b-button>
          </b-col>
          <b-col class="d-flex justify-content-for-mobile">
            <b-button
              type="submit"
              variant="outline-primary"
              class="mr-1"
              @click.prevent="cancelar()"
            >
              <span>
                Cancelar
              </span>
            </b-button>
            <b-button
              type="submit"
              variant="custom"
              class="mr-1"
              @click.prevent="salvar()"
            >
              <span>
                Salvar e habilitar
              </span>
            </b-button>
          </b-col>
        </b-row>
      </div>
      <div class="mt-1"><span class="p-1"> </span>
      </div>
    </validation-observer>
  </b-overlay>
</div>
</template>

<script>
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
  import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
  import { required } from "@validations";
  import { heightFade } from '@core/directives/animations';
  import Ripple from 'vue-ripple-directive';
  import {
    modalGenericModel
  } from '@/libs/sweetalerts';
  import {
    BFormTextarea,
    BCardText,
    BCardBody,
    BCard,
    BCardHeader,
    BFormInput,
    BRow,
    BCol,
    BButton,
    BOverlay,
    BCollapse,
    BFormRadio,
    BInputGroup,
    BInputGroupText,
    BInputGroupAppend,
    BImg,
    BAlert,
    BFormCheckbox
  } from "bootstrap-vue";

  export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    AppCollapse,
    AppCollapseItem,
    required,
    BFormTextarea,
    BCardText,
    BCardBody,
    BCard,
    BCardHeader,
    BFormInput,
    BRow,
    BCol,
    BButton,
    BOverlay,
    BCollapse,
    BFormRadio,
    BInputGroup,
    BInputGroupText,
    BInputGroupAppend,
    BImg,
    BAlert,
    BFormCheckbox
  },

  directives: {
    heightFade,
    Ripple,
  },

  data() {
    return {
      loadingPage: true,
      mostrarEdicao: false,
      totalNumbers: 0,
      currentIndex: 1,
      mostraQuestao: 0,
      mostrarAlerta: false,
      mostrarTextoBotao: true,
      questionario: {
        idQuestionario: null,
        descricao: '',
        descricao_tecnica: '',
        usarFeedback: null,
        em_uso: null,
        situacao: 'Ativo',
        qtdQuestoes: null,
        questoes: [{
          qtdAlternativas: 2,
          pontuacaoTotal: 0,
          enunciado: null,
          tipoSelecao: {
            selecaoMultipla: false,
            multiplaEscolha: false
          },
          alternativas: [
            {
              opcao: null,
              peso: null
            },
            {
              opcao: null,
              peso: null
            }
          ]
        }],
        feedbacks: [],
      },
      pontuacao: {
        minimo: 0,
        maximo: 0,
        intervaloA: 0,
        intervaloB: 0,
        intervaloC: 0,
        intervaloD: 0,
        intervaloE: 0,
        intervaloF: 0,
        intervaloG: 0,
        intervaloH: 0,
      },
      labelAlternativa:{
        1: 'a',
        2: 'b',
        3: 'c',
        4: 'd',
        5: 'e',
        6: 'f',
        7: 'g',
        8: 'h',
      },
      collapseVisible: {
        visibleOptionsQuestion: true,
        1: true,
        2: false,
        3: false,
        4: false,
      }
    };
  },

  computed: {
    displayedNumbers(){
      this.totalNumbers = this.questionario.questoes.length;
      const numbers = [];
      const numToShow = Math.min(7, this.totalNumbers);
      for (let i = 0; i < numToShow; i++) {
        let number = this.currentIndex + i;
        if (number > this.totalNumbers) {
          number -= this.totalNumbers;
        }
        numbers.push(number);
      }
      return numbers;
    }
  },

  async mounted(){
    await this.carregaQuestionario();
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    this.loadingPage = false;
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },

  props: {
    questionarioData: {
      type: Object,
      required: true
    },
  },

  methods: {
    handleResize() {
      this.mostrarTextoBotao = window.innerWidth >= 760;
    },
    async carregaQuestionario(){
      if(!this.questionarioData){
        this.$router.push({ name: 'listagem-questionarios'});
      }else{
        this.questionario.idQuestionario       = this.questionarioData.idQuestionario;
        this.questionario.descricao            = this.questionarioData.descricao;
        this.questionario.descricao_tecnica    = this.questionarioData.descricao_tecnica;
        this.questionario.usarFeedback         = this.questionarioData.usar_feedback;
        this.questionario.questoes             = this.questionarioData.questoes;
        this.questionario.feedbacks            = this.questionarioData.feedback ? this.questionarioData.feedback : [];
        this.questionario.em_uso               = this.questionarioData.em_uso;
      }
      if(this.questionarioData.feedback.length== 0){
        this.inserirFeedback();
        this.inserirFeedback();
      }
      this.zeraPontuacaoMinimaMaxima();
      this.atualizaMinimoMaximo();
    },
    atualizaMinimoMaximo(){
      let minimo = 0;
      let maximo = 0;
      for(let i=0; i < this.questionario.questoes.length; i++){

        minimo = minimo + this.procuraMenorValor(i);
        maximo = maximo + this.procuraMaiorValor(i);
      }
      this.pontuacao.minimo = minimo;
      this.pontuacao.maximo = maximo;
      if(this.questionario.usarFeedback){
        this.questionario.feedbacks[0].valorInicial = minimo;
      }
      this.preencheIntervalos();
    },
    preencheIntervalos(){
      let diferenca = this.pontuacao.maximo - this.pontuacao.minimo;
      if(this.questionario.feedbacks.length <= 2){
        this.pontuacao.intervaloA = parseInt(this.pontuacao.minimo);
        this.pontuacao.intervaloB = parseInt(this.pontuacao.minimo) + parseInt(Math.trunc(diferenca/2));
        this.pontuacao.intervaloC = parseInt(this.pontuacao.intervaloB) + parseInt(1);
        this.pontuacao.intervaloD = parseInt(this.pontuacao.maximo);
      }
      if(this.questionario.feedbacks.length === 3){
        this.pontuacao.intervaloA = parseInt(this.pontuacao.minimo);
        this.pontuacao.intervaloB = parseInt(this.pontuacao.minimo) + parseInt(Math.trunc(diferenca/3));
        this.pontuacao.intervaloC = parseInt(this.pontuacao.intervaloB) + parseInt(1);
        this.pontuacao.intervaloD = parseInt(this.pontuacao.intervaloC) + parseInt(Math.trunc(diferenca/3));
        this.pontuacao.intervaloE = parseInt(this.pontuacao.intervaloD) + parseInt(1);
        this.pontuacao.intervaloF = parseInt(this.pontuacao.maximo);
      }
      if(this.questionario.feedbacks.length === 4){
        this.pontuacao.intervaloA = parseInt(this.pontuacao.minimo);
        this.pontuacao.intervaloB = parseInt(this.pontuacao.minimo) + parseInt(Math.trunc(diferenca/4));
        this.pontuacao.intervaloC = parseInt(this.pontuacao.intervaloB) + parseInt(1);
        this.pontuacao.intervaloD = parseInt(this.pontuacao.intervaloB) + parseInt(Math.trunc(diferenca/4));
        this.pontuacao.intervaloE = parseInt(this.pontuacao.intervaloD) + parseInt(1);
        this.pontuacao.intervaloF = parseInt(this.pontuacao.intervaloD) + parseInt(Math.trunc(diferenca/4));
        this.pontuacao.intervaloG = parseInt(this.pontuacao.intervaloF) + parseInt(1)
        this.pontuacao.intervaloH = parseInt(this.pontuacao.maximo);
      }
    },
    procuraMenorValor(indice){
      let minimo = parseInt(37);
      for(let alternativa of this.questionario.questoes[indice].alternativas){
        if(alternativa.peso != null && alternativa.peso !=0){
          if(alternativa.peso && alternativa.peso < minimo){
            minimo = parseInt(alternativa.peso);
          }
        }
      }
      if(minimo === 37){
        return 0;
      }else{
        return minimo;
      }
    },
    procuraMaiorValor(indice){
      let maximo = 0;
      if(this.questionario.questoes[indice].tipoSelecao.selecaoMultipla){
        maximo = 36;
      }else{
        for(let alternativa of this.questionario.questoes[indice].alternativas){
          if(alternativa.peso != null && alternativa.peso !=0){
            if(alternativa.peso && alternativa.peso > maximo){
              maximo = parseInt(alternativa.peso);
            }
          }
        }
      }
      return maximo;
    },
    zeraPontuacaoMinimaMaxima(){
      this.pontuacao.minimo = 0;
      this.pontuacao.maximo = 0;
      this.pontuacao.intervaloA = 0;
      this.pontuacao.intervaloB = 0;
      this.pontuacao.intervaloC = 0;
      this.pontuacao.intervaloD = 0;
      this.pontuacao.intervaloE = 0;
      this.pontuacao.intervaloF = 0;
      this.pontuacao.intervaloG = 0;
      this.pontuacao.intervaloH = 0;
    },
    inserirFeedback(){
      let qtdFeedback = this.questionario.feedbacks.length;
      if(qtdFeedback<4){
        let feedback = {
            valorInicial: 0,
            valorFinal: 0,
            mensagem: ''
        };
        this.questionario.feedbacks.push(feedback);
      }
      this.zeraPontuacaoMinimaMaxima();
      this.atualizaMinimoMaximo();
      this.mostrarAlerta = true;
      this.collapseVisible[this.questionario.feedbacks.length] = true;
    },
    alteraValorFeedback(i){
      if(this.questionario.feedbacks[i].valorFinal){
        let valorInicial = parseInt(this.questionario.feedbacks[i].valorInicial);
        let valorFinal   = parseInt(this.questionario.feedbacks[i].valorFinal);
        if(valorFinal<valorInicial || valorFinal > this.pontuacao.maximo){
          this.mostrarAlerta = true;
        }else{
          this.questionario.feedbacks[i+1].valorInicial = valorFinal+1;
        }
      }
      this.verificaFeedbackPreenchido();
    },
    verificaFeedbackPreenchido(){
      let mostrar = false;
      if(this.questionario.usarFeedback){
        let x = this.questionario.feedbacks.length;
        this.questionario.feedbacks[x-1].valorFinal = this.pontuacao.maximo;

        for(let i=0; i<x; i++){
          if(this.questionario.feedbacks[i].valorFinal < this.questionario.feedbacks[i].valorInicial){
            mostrar = true;
          }
          if(!this.questionario.feedbacks[i].valorInicial ||
             !this.questionario.feedbacks[i].valorFinal ||
             !this.questionario.feedbacks[i].mensagem
          ){
              mostrar = true;
          }
          this.mostrarAlerta = mostrar;
        }
      }
    },
    removeFeedback(){
      if(this.questionario.feedbacks.length > 2){
        this.questionario.feedbacks.pop();
      }
      this.zeraPontuacaoMinimaMaxima();
      this.atualizaMinimoMaximo();
      this.verificaFeedbackPreenchido();
    },
    exportarQuestionario(){
      modalGenericModel(
        'Exportar Questionário',
        'Ao exportar o questionário você poderá revisar todas as informações que cadastrou. ' +
        'Você pode deixar seu questionário salvo como rascunho enquanto isso!',
        require('@/assets/custom-icons/cora-icons/download.png'),
        'Entendi'
      ).then((result) => {
        if (result.isConfirmed) {
          this.baixarQuestionario();
        }else{
          return ;
        }
      });
    },
    async baixarQuestionario(){
      this.loadingPage = true;
      let questionario = this.formataDadosQuestionario();
      await this.$http.post(this.$api.questionario.exportarRespostasPdf, questionario, {responseType: "blob"})
        .then(response => {
          const link = document.createElement('a');
          link.href = URL.createObjectURL(response.data);
          link.download = questionario.descricao+'.pdf';
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch((error) => {
          modalGenericModel(
            error.response.data.error,
            '',
            require('@/assets/custom-icons/cora-icons/warning.png'),
            'Entendi'
          )
        });
      this.loadingPage = false;
    },
    formataDadosQuestionario(){
      this.questionario.qtdQuestoes = this.questionario.questoes.length;
      for(let questoes of this.questionario.questoes){
        questoes.pontuacaoTotal = 36;
      }
      return {
        idQuestionario:   this.questionario.idQuestionario,
        descricao:         this.questionario.descricao,
        descricao_tecnica: this.questionario.descricao_tecnica,
        situacao:          this.questionario.situacao,
        usar_feedback:     this.questionario.usarFeedback ,
        qtdQuestoes:       this.questionario.qtdQuestoes,
        questoes:          this.questionario.questoes,
        feedback:          this.questionario.usarFeedback ? this.questionario.feedbacks : []
      };
    },
    cancelar(){
      modalGenericModel(
        'Cancelar',
        'Você tem certeza que deseja cancelar e perder as alterações cadastradas?',
        require('@/assets/custom-icons/cora-icons/warning.png'),
        'Sim, cancelar',
        'Não, continuar editando'
      ).then((result) => {
        if (result.isConfirmed) {
          this.$router.push({ name: 'listagem-questionarios' });
        }else{
          return ;
        }
      });
    },
    salvar(){
      if(this.questionario.em_uso){
        modalGenericModel(
          'Salvar nova versão',
          'Este questionário já foi utilizado em uma aplicação e só poderá ser salvo como uma nova versão. Deseja continuar?',
          require('@/assets/custom-icons/cora-icons/warning.png'),
          'Continuar',
          'Cancelar'
        ).then((result) => {
          if (result.isConfirmed) {
            this.inserirEUsar();
          }
        });
      }else{
        modalGenericModel(
          'Salvar e Habilitar',
          'Ao salvar e habilitar para uso, o questionário ficará na lista de questionários criados ativos e você poderá criar uma aplicação a partir dele.',
          require('@/assets/custom-icons/cora-icons/success.png'),
          'Sim, prosseguir',
          'Cancelar'
        )
        .then((result) => {
          if (result.isConfirmed) {
            this.inserirEUsar();
          }
        });
      }
    },
    async inserirEUsar(){
      let valorValido = false;
      for(let feedback of this.questionario.feedbacks){
        if(
          feedback.valorInicial < this.pontuacao.minimo ||
          feedback.valorInicial > this.pontuacao.maximo ||
          feedback.valorFinal < this.pontuacao.minimo ||
          feedback.valorFinal > this.pontuacao.maximo
        ){
          valorValido = true;
        }
      }
      if(valorValido || this.mostrarAlerta){
        modalGenericModel(
          'Atenção',
          'Confira o preenchimento correto da pontuação e dos textos de feedback antes de finalizar.',
          require('@/assets/custom-icons/cora-icons/warning.png'),
          'Entendi'
        )
        this.pontuacao.valorValido = true;
      }else{
        this.loadingPage = true;
        let questionario = this.formataDadosQuestionario();
        await this.$http.put(this.$api.questionario.atualizar, questionario)
          .then(() => {
            modalGenericModel(
              'Sucesso!',
              'Sua atualização no questionário foi salva com sucesso!',
              require('@/assets/custom-icons/cora-icons/success.png'),
              'Entendi'
            ).then(() => {
              this.$router.push({ name: 'listagem-questionarios' })
            })
          })
          .catch((error) => {
            modalGenericModel(
              error.response.data.error,
              '',
              require('@/assets/custom-icons/cora-icons/warning.png'),
              'Entendi'
            )
          });
      }
      this.loadingPage = false;
    },
  },
};
</script>

<style lang="scss" scoped>
  .question{
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 1.375rem;
    letter-spacing: 0px;
  }
  .title-question{
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 1.5rem;
    letter-spacing: 0px;
  }
  .border-collapse{
    border: 1px solid #D8D6DE;
    border-radius: 5px;
    box-shadow: 0px 4px 24px 0px #0000000F;
  }
  .description-feedback-area{
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.25rem;
    letter-spacing: 0px;
     @media (max-width: 990px) {
      display: flex;
      justify-content: center;
      margin: 0 10%;
    }
  }
  .resize-none{
    resize: none;
  }
  .width-custom{
    width: 1.625rem;
    height: 1.625rem;
    padding: 0;
    text-align: center;
    margin-right: 0.325rem;
  }
  .separator{
    width: 100%;
    border-bottom: 1px solid #D8D6DE;
  }
  .custom-space-alert{
    width: 100%;
    margin: 0 auto;
  }
  .img-cora-properties{
    width: 6.625rem;
  }
  .elemento{
    padding: 10px 20px;
    font-size: 1.125rem;
    background-color: #E5EEF7;
    border-radius: 10px;
    line-height: 1.25rem;
    letter-spacing: 0px;
  }
  .col-img-cora{
    text-align: right;
    @media(max-width: 1500px){
      margin-right: 2rem;
    }
    @media (max-width: 990px) {
      text-align: center;
    }
  }
  .col-msg-cora{
    text-align: left;
    align-items: left;
    @media (max-width: 990px) {
      text-align: center;
    }
  }
  .row-msg-cora{
    @media (max-width: 990px) {
      display: flex;
      justify-content: center;
    }
  }
  .custom-margin-top-cora{
    margin-top: 1.5rem;
  }
  .area-botoes{
    border-bottom: 1.5rem solid transparent;
  }
  .display-colum-for-mobile{
    @media (max-width: 990px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
  .space-beetwen-button-text{
    margin-left: 8px;
  }
  .justify-content-for-mobile{
    justify-content: end;
    @media (max-width: 596px) {
      justify-content: start;
      margin-left: 1rem;
    }
  }
  .display-cutom-mobile{
    width: 50%;
    align-items: center;
    @media (max-width: 990px) {
      display: flex;
      justify-content: center;
      margin: 0 auto;
    }
  }
</style>
